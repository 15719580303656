/**
 * All the styles related with the trix text editor of the application.
 * The file is being loaded in app.scss
 */

/* Hide attachments button from Trix text editor. */
/* =============================================================== */
.trix-button-group--file-tools {
    display: none !important;
}

/* Cotent height. */
/* =============================================================== */
trix-editor {
    min-height: 300px !important;
    background-color: #fff;
    border-radius: 0px;
}
