/**
 * All the styles related with the sidebar of the application.
 * The file is being loaded in app.scss
 */

/* Overlay */
/* =============================================================== */
.overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.2s ease-in-out;
    opacity: 0;
    z-index: 998;
}
.overlay.active {
    display: block;
    opacity: 1;
}

/* Sidebar */
/* =============================================================== */
#sidebar {
    min-width: 250px;
    max-width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    width: 250px;
    left: -250px;
    z-index: 999;
    color: #fff;
    transition: all 0.3s;
    background-color: #202020;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    .btn-dark {
        color: #fff;
        font-size: 18px;
        background-color: transparent;
        border: none;
    }
    .nav {
        .nav-item {
            .nav-link {
                color: #fff;
                font-size: 0.8rem;
                font-weight: normal;
                line-height: 1.8rem;
            }
            &:hover {
                background-color: #383838;
            }
        }
        .nav-item-separator {
            margin: 0.5rem 0;
            border-bottom: 1px solid #343a40;
        }
        .nav-title {
            margin-bottom: 15px;
            font-weight: 400;
            padding: 0 1rem;
            text-transform: uppercase;
        }
    }
}
#sidebar.active {
    left: 0;
}
.simplebar-scrollbar:before {
    right: 0;
    left: 1px;
    border-radius: 0px;
}
.simplebar-track.simplebar-vertical {
    width: 8px;
}
