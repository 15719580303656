/**
 * All the styles related with the application.
 * The file is being loaded in app.scss
 */

/* Colors */
/* =============================================================== */
.bg-dark {
    background-color: #212121 !important;
}

/* Navbar */
/* =============================================================== */
#navbar {
    #navbar-search {
        input {
            color: #fff;
            background-color: #121212;
            border-color: #303030;
        }
        .btn-search {
            font-size: 12px;
            border: none;
            background-color: rgba(255,255,255,0.08);
            padding: 8px 14px;
        }
    }
    .btn-dark {
        color: #fff;
        font-size: 18px;
        background-color: transparent;
        border: none;
    }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
        box-shadow: none;
    }
    .btn-dark:focus, .btn-dark.focus {
        box-shadow: none;
    }
    .btn:focus, .btn.focus {
        box-shadow: none;
    }
    .dropdown-menu {
        margin: 0.45rem 0 0;
    }
    .dropdown-menu-avatar {
        margin: 0.55rem 0 0;
    }
    .form-control:focus {
        box-shadow: none;
    }
}

/* Radius */
/* =============================================================== */
.card, .badge, .form-control, .btn, .dropdown-menu, .modal-content {
    border-radius: 0px;
}

/* Cards */
/* =============================================================== */
.card {
    .card-header {
        border: none;
        background-color: transparent;
        font-weight: normal;
    }
}

/* Tables */
/* =============================================================== */
table {
    th {
        font-weight: 600;
        border-bottom: solid 1px #ddd;
    }
    td {
        white-space: nowrap !important;
        vertical-align: middle !important;
        a {
            font-weight: 600;
        }
    }
}

/* Pagination */
/* =============================================================== */
.pagination {
   justify-content: center;
}

/* Forms */
/* =============================================================== */
label.required {
    &:after {
        color: $danger;
        content: ' *';
    }
}

/* Dropdowns */
/* =============================================================== */
.dropdown-menu {
    padding: 0px;
    .dropdown-item {
        padding: 0.6rem 1.5rem;
    }
    .dropdown-divider {
        margin: 0px;
    }
}

/* Buttons */
/* =============================================================== */
.btn {
    text-transform: uppercase;;
    font-weight: normal;
    letter-spacing: .5px;
    padding: 8px 14px;
    font-size: 12px;
}

/* Badges */
/* =============================================================== */
.badge {
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    letter-spacing: .5px;
    vertical-align: middle;
}

/* Images */
/* =============================================================== */
.image-block {
    .btn-remove-image {
        position: absolute;
        top: 0px;
        left: 15px;
        padding: 2px 7px !important;
    }
}

    .dropdown-menu>a{
    font-weight: normal;
    color:#fff;
  }
  
  .dropdown-menu {
    font-weight: normal;
    background-color: #008DD0;
  }
  
  .dropdown-menu>a:hover {
    font-weight: normal;
    color: #f58233;
    background-color: #008DD0;
  }

  .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    font-weight: normal;
    background-color: #f58233 !important;
    border: 1px solid #f58233;
  }

  .btn-default{
    font-weight: normal;
    color:#008DD0;
  }
  
  .btn-default:hover, .btn-default:active, .btn-default:visited {
    font-weight: normal;
    color: #f58233 !important;
  }

  .title {
    color:white;
    font-size: 2em!important;
    font-weight: 300;
    padding: 15px;
    opacity:0.7;
    background-color: rgba(0,141,2081,.6);
    text-shadow: 2px 2px 5px black;
    border-radius: 3px;
  }




  
 


