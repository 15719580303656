/*
 |--------------------------------------------------------------------------
 | Select 2 styles
 |--------------------------------------------------------------------------
 |
 | This file provides the necesary styles for the select2 javascript plugin.
 |
 */

 .select2-container--default .select2-selection--single {
	color: #555555 !important;
	background-color: white;
	border-width: 1px;
	border-style: solid;
	border-color: rgba(197, 214, 222, 0.7);
	border-radius: 0px;
	min-height: 34px;
    cursor: default;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #555555 !important;
	padding: 2px 15px !important;
}
.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple {
	color: #555555 !important;
    background-color: white;
    padding-left: 6px !important;
	border-width: 1px;
	border-style: solid;
	border-color: rgba(197, 214, 222, 0.7);
	border-radius: 0px;
	min-height: 34px;
    cursor: default;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #555555 !important;
	padding: 2px 15px !important;
}
.select2-container--open .select2-dropdown--above,
.select2-container--open .select2-dropdown--below {
	color: #555555 !important;
	background-color: white;
	border-width: 1px;
	border-style: solid;
	border-color: rgba(197, 214, 222, 0.7);
	border-radius: 0px;
}
.has-error .select2-selection {
    border: 1px solid #a94442;
    border-radius: 0px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 4px !important;
    right: 5px !important;
}
.select2-selection__clear {
	right: 20px;
    color: #555555 !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
	color: #fff;
	opacity: 0.9;
	background-color: #008DD0;
    color: #333;
    border: 1px solid #008DD0;
    border-radius: 0px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #333;
    margin-left: 5px;
	float: right;
}
.tab-pane {
	.select2 {
		width: 100% !important;
	}
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #008DD0 !important;
    color: #333;
    cursor: default !important;
}
.select2-results__option[aria-selected] {
    cursor: default !important;
}
.select2 {
	width: 100% !important;
	font-family: 'FontAwesome',sans-serif;
}
