@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap&subset=greek');

@import 'variables';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';

@import '~bootstrap/scss/bootstrap';

@import '~simplebar/dist/simplebar';

@import '~flatpickr/dist/themes/airbnb';

@import '~trix';

@import '~toastr/toastr';

@import '~@staaky/fresco/dist/css/fresco';

@import 'overrides';
@import 'sidebar';
@import 'trix';
@import 'flatpickr';
@import 'select2';
